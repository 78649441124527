import React, { useState, useEffect } from "react"
import Nav from "../components/nav"
import * as EmailValidator from "email-validator"
import emailjs from "emailjs-com"
import "reactjs-popup/dist/index.css"
import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import MyHelmet from "../components/helmet"
import Footer from "../components/footer"
import { FcAssistant } from "react-icons/fc"
import { BiMailSend, BiPhoneCall } from "react-icons/bi"
import PageTransition from "gatsby-plugin-page-transitions"

export default function Contact() {
  const [name, setName] = useState("")
  const [business, setBusiness] = useState("")
  const [email, setEmail] = useState("")
  const [number, setNumber] = useState("")
  const [message, setMessage] = useState("")
  const [emailIsValid, setEmailIsValid] = useState(true)
  const [phoneIsValid, setPhoneIsValid] = useState(true)
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDark, setIsDark] = useState(false)

  useEffect(() => {
    setIsDark(window?.localStorage.getItem("isDark") === "true")
  }, [])

  const onChange = e => {
    const val = e.target.value
    const name = e.target.name

    switch (name) {
      case "name":
        setName(val)
        break
      case "business":
        setBusiness(val)
        break
      case "email":
        setEmail(val)
        break
      case "number":
        if (!isNaN(val)) {
          setNumber(val)
        }
        break
      case "message":
        setMessage(val.slice(0, 500))
        break
      default:
        break
    }
  }

  const onBlur = e => {
    const name = e.target.name

    switch (name) {
      case "email":
        setEmailIsValid(EmailValidator.validate(email))
        break
      case "number":
        setPhoneIsValid(number.toString().length === 10)
        break
      default:
        break
    }
  }

  function sendEmail(e) {
    e.preventDefault()
    setIsLoading(true)

    emailjs
      .sendForm(
        "service_c0pfayo",
        "template_w6yvvmq",
        e.target,
        "user_SyIaXHkk7wlfG8SikOg2A"
      )
      .then(
        result => {
          if (result.status === 200) {
            refreshState(true)
          }
        },
        error => {
          if (error) {
            refreshState(false)
          }
        }
      )
  }

  const closePopup = () => {
    if (isSuccessful) {
      window.location.href = "/"
    }

    setIsLoading(false)
    setIsError(false)
  }

  const refreshState = isSuccessful => {
    if (!isSuccessful) setIsError(true)

    setIsSuccessful(true)
  }

  const inputConfig = {
    className: "input",
    onChange,
    autoComplete: "off",
    autoCorrect: "off",
    autoCapitalize: "none",
    spellCheck: "false",
  }

  return (
    <PageTransition>
      <div className={`${isDark ? "dark" : ""}`}>
        <MyHelmet />
        <Nav
          currentPage="contact"
          backgroundColorProp="new-password"
          optionalHeader="Contact Us"
          isDark={isDark}
          setIsDark={setIsDark}
        />
        <div className="contact-us-container">
          <div className="contact-us">
            <div className="header">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <FcAssistant
                  style={{
                    width: 100,
                    height: 100,
                  }}
                />
              </div>
              <h1 className="title">
                Give us a call, email us, or fill out the form below!
              </h1>
              <p className="line">
                <BiMailSend /> flyingfishweb@gmail.com
              </p>
              <p className="line">
                <BiPhoneCall /> 832-622-6336
              </p>
            </div>
            <form onSubmit={sendEmail} className="body">
              <input
                name="name"
                placeholder="Name"
                value={name || ""}
                type="search"
                {...inputConfig}
              />
              <input
                name="email"
                placeholder="Email Address"
                onBlur={onBlur}
                value={email || ""}
                {...inputConfig}
                type="email"
                className={`input ${emailIsValid ? "" : "is-danger"}`}
              />
              {!emailIsValid ? (
                <p className="valid">Please enter a valid email.</p>
              ) : (
                ""
              )}
              <input
                name="business"
                placeholder="Business"
                value={business || ""}
                type="search"
                {...inputConfig}
              />
              <input
                name="number"
                placeholder="Phone Number"
                onBlur={onBlur}
                value={number || ""}
                type="tel"
                {...inputConfig}
              />
              {!phoneIsValid ? (
                <p className="valid">Please enter a valid phone number.</p>
              ) : (
                ""
              )}
              <textarea
                name="message"
                placeholder="Your Message..."
                value={message || ""}
                {...inputConfig}
                className="textarea"
              />
              <p className="remaining">
                {500 - message.length} characters remaining
              </p>
              <button
                className={`button is-link ${isLoading ? "is-loading" : ""}`}
                disabled={
                  !(name && business && message && emailIsValid && phoneIsValid)
                }
              >
                Submit
              </button>
            </form>
          </div>
        </div>
        <div>
          <Modal
            open={isSuccessful || isError}
            onClose={closePopup}
            onBlur={closePopup}
          >
            <p>
              {isSuccessful
                ? "Success! We'll get back to you shortly."
                : "Hmm, an error occurred. Can you try again? If the problem persists, just give us a call."}
            </p>
          </Modal>
        </div>
        <Footer />
      </div>
    </PageTransition>
  )
}
